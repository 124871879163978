import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/form'
  },
  {
    path: '/form',
    name: 'form',
    component: () => import(/* webpackChunkName: "form" */ '../views/FormView.vue')
  },
  {
    path: '/picture',
    name: 'picture',
    component: () => import(/* webpackChunkName: "form" */ '../views/PictureView.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "form" */ '../views/NoticeView.vue')
  },
  {
    path: '/notice2',
    name: 'notice2',
    component: () => import(/* webpackChunkName: "form" */ '../views/Notice2View.vue')
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: () => import(/* webpackChunkName: "form" */ '../views/ThanksView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
