import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    age: null,
    pseudo: null,
    pictureNbr: null,
    infoSup: null,
    makeup: null,
    mail: null,
    sex: null,
    skin: null,
    paramsSetted: false
  },
  getters: {
    getParams (state) {
      return {
        age: state.age,
        pseudo: state.pseudo,
        pictureNbr: state.pictureNbr,
        infoSup: state.infoSup,
        makeup: state.makeup,
        mail: state.mail,
        sex: state.sex,
        skin: state.skin
      }
    },
    getParamsSetted (state) {
      return state.paramsSetted
    }
  },
  mutations: {
    setFormValues (state, values) {
      Object.keys(values).forEach(key => { state[key] = values[key] })
      state.paramsSetted = true
    }
  },
  actions: {
  },
  modules: {
  }
})
